export interface CountryDataInterface {
  available?: string
  country: string
  countryCode: string
  incomplete?: string
  tier?: number
}

export const countryData: Array<CountryDataInterface> = [
  { countryCode: 'AD', country: 'ANDORRA', available: '', incomplete: 'AD', tier: 4 },
  { countryCode: 'AE', country: 'UNITED ARAB EMIRATES', available: 'PCT', incomplete: 'AE', tier: 3 },
  { countryCode: 'AF', country: 'AFGHANISTAN', available: '', incomplete: 'AF', tier: 4 },
  { countryCode: 'AG', country: 'ANTIGUA' },
  { countryCode: 'AI', country: 'ANGUILLA' },
  { countryCode: 'AL', country: 'ALBANIA', available: 'PCT, EP', incomplete: 'AL, EP Validations', tier: 2 },
  { countryCode: 'AM', country: 'ARMENIA', available: 'PCT, EA', incomplete: 'AM', tier: 2 },
  { countryCode: 'AN', country: 'NETHERLANDS ANTILLES' },
  { countryCode: 'AO', country: 'ANGOLA' },
  { countryCode: 'AR', country: 'ARGENTINA', available: 'AR', incomplete: '', tier: 1 },
  { countryCode: 'AS', country: 'AMERICAN SAMOA' },
  { countryCode: 'AT', country: 'AUSTRIA', available: 'PCT, EP, AT, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'AU', country: 'AUSTRALIA', available: 'PCT, AU', incomplete: '', tier: 1 },
  { countryCode: 'AW', country: 'ARUBA' },
  { countryCode: 'AZ', country: 'AZERBAIJAN', available: 'PCT, EA', incomplete: 'AZ', tier: 2 },
  {
    countryCode: 'BA',
    country: 'BOSNIA AND HERZEGOVINA',
    available: 'PCT, EP',
    incomplete: 'BA, EP Validations',
    tier: 2,
  },
  { countryCode: 'BB', country: 'BARBADOS' },
  { countryCode: 'BD', country: 'BANGLADESH', available: '', incomplete: 'BD', tier: 4 },
  { countryCode: 'BE', country: 'BELGIUM', available: 'PCT, EP, BE, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'BF', country: 'BURKINA FASO', available: 'PCT', incomplete: 'OA, BF', tier: 3 },
  { countryCode: 'BG', country: 'BULGARIA', available: 'PCT, EP', incomplete: 'BG, EP Validations', tier: 2 },
  { countryCode: 'BH', country: 'BAHRAIN' },
  { countryCode: 'BI', country: 'BURUNDI', available: '', incomplete: 'BI', tier: 4 },
  { countryCode: 'BJ', country: 'BENIN', available: 'PCT', incomplete: 'OA, BJ', tier: 3 },
  { countryCode: 'BM', country: 'BERMUDA', available: '', incomplete: 'BM', tier: 4 },
  { countryCode: 'BN', country: 'BRUNEI', available: 'PCT, BN', incomplete: '', tier: 1 },
  { countryCode: 'BO', country: 'BOLIVIA', available: '', incomplete: 'BO', tier: 4 },
  { countryCode: 'BR', country: 'BRAZIL', available: 'PCT, BR', incomplete: '', tier: 1 },
  { countryCode: 'BS', country: 'BAHAMAS', available: '', incomplete: 'BS', tier: 4 },
  { countryCode: 'BT', country: 'BHUTAN', available: '', incomplete: 'BT', tier: 4 },
  { countryCode: 'BV', country: 'BOUVET ISLAND' },
  { countryCode: 'BW', country: 'BOTSWANA', available: 'PCT, AP', incomplete: 'BW', tier: 2 },
  { countryCode: 'BY', country: 'BELARUS', available: 'PCT, EA', incomplete: 'BY', tier: 2 },
  { countryCode: 'BZ', country: 'BELIZE' },
  { countryCode: 'CA', country: 'CANADA', available: 'PCT, CA', incomplete: '', tier: 1 },
  { countryCode: 'CC', country: 'COCOS ISLANDS' },
  { countryCode: 'CG', country: 'CONGO', available: '', incomplete: 'OA, CG', tier: 4 },
  { countryCode: 'CF', country: 'CENTRAL AFRICAN REPUBLIC', available: 'PCT', incomplete: 'OA, CF', tier: 3 },
  { countryCode: 'CG', country: 'REPUBLIC OF CONGO', available: '', incomplete: 'OA, CG', tier: 4 },
  { countryCode: 'CH', country: 'SWITZERLAND', available: 'PCT, EP, CH, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'CI', country: "CÔTE D'IVOIRE", available: 'PCT', incomplete: 'OA, CI', tier: 3 },
  { countryCode: 'CK', country: 'COOK ISLANDS' },
  { countryCode: 'CL', country: 'CHILE', available: 'PCT, CL', incomplete: '', tier: 1 },
  { countryCode: 'CM', country: 'CAMEROON', available: 'PCT', incomplete: 'OA, CM', tier: 3 },
  { countryCode: 'CN', country: 'CHINA', available: 'PCT, CN', incomplete: '', tier: 1 },
  { countryCode: 'CO', country: 'COLOMBIA', available: 'PCT, CO', incomplete: '', tier: 1 },
  { countryCode: 'CR', country: 'COSTA RICA', available: 'PCT', incomplete: 'CR', tier: 3 },
  { countryCode: 'CU', country: 'CUBA', available: '', incomplete: 'CU', tier: 4 },
  { countryCode: 'CV', country: 'CAPE VERDE', available: '', incomplete: 'CV', tier: 4 },
  { countryCode: 'CX', country: 'CHRISTMAS ISLAND' },
  { countryCode: 'CY', country: 'CYPRUS', available: 'PCT, EP', incomplete: 'CY, EP Validations', tier: 2 },
  { countryCode: 'CZ', country: 'CZECH REPUBLIC', available: 'PCT, EP', incomplete: 'CZ, EP Validations', tier: 2 },
  { countryCode: 'DD', country: 'GERMAN DEMOCRATIC REPUBLIC', available: '', incomplete: 'DD', tier: 4 },
  { countryCode: 'DE', country: 'GERMANY', available: 'PCT, EP, DE, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'DJ', country: 'DJIBOUTI' },
  { countryCode: 'DK', country: 'DENMARK', available: 'PCT, EP, DK, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'DM', country: 'DOMINICA' },
  { countryCode: 'DO', country: 'DOMINICAN REPUBLIC', available: 'PCT', incomplete: 'DO', tier: 3 },
  { countryCode: 'DZ', country: 'ALGERIA', available: 'PCT', incomplete: 'DZ', tier: 3 },
  { countryCode: 'EC', country: 'ECUADOR', available: 'PCT', incomplete: 'EC', tier: 3 },
  { countryCode: 'EE', country: 'ESTONIA', available: 'PCT, EP', incomplete: 'EE, EP Validations', tier: 2 },
  { countryCode: 'EG', country: 'EGYPT', available: 'PCT', incomplete: 'EG', tier: 3 },
  { countryCode: 'EH', country: 'WESTERN SAHARA' },
  { countryCode: 'ER', country: 'ERITREA' },
  { countryCode: 'ES', country: 'SPAIN', available: 'PCT, EP, ES, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'ET', country: 'ETHIOPIA', available: '', incomplete: 'ET', tier: 4 },
  { countryCode: 'EU', country: 'EUROPEAN UNION' },
  { countryCode: 'FI', country: 'FINLAND', available: 'PCT, EP, FI, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'FJ', country: 'FIJI', available: '', incomplete: 'FJ', tier: 4 },
  { countryCode: 'FK', country: 'FALKLAND ISLANDS' },
  { countryCode: 'FM', country: 'MICRONESIA' },
  { countryCode: 'FO', country: 'FAROE ISLANDS' },
  { countryCode: 'FR', country: 'FRANCE', available: 'PCT, EP, FR, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'GA', country: 'GABON', available: 'PCT', incomplete: 'OA, GA', tier: 3 },
  {
    countryCode: 'GB',
    country: 'UNITED KINGDOM',
    available: 'PCT, EP, GB, EP Validations',
    incomplete: '',
    tier: 1,
  },
  { countryCode: 'GD', country: 'GRENADA' },
  { countryCode: 'GE', country: 'GEORGIA', available: 'PCT', incomplete: 'GE', tier: 3 },
  { countryCode: 'GF', country: 'FRENCH GUIANA' },
  { countryCode: 'GH', country: 'GHANA', available: 'PCT, AP', incomplete: 'GH', tier: 2 },
  { countryCode: 'GI', country: 'GIBRALTAR' },
  { countryCode: 'GL', country: 'GREENLAND' },
  { countryCode: 'GM', country: 'GAMBIA' },
  { countryCode: 'GN', country: 'GUINEA', available: 'PCT', incomplete: 'OA, GN', tier: 3 },
  { countryCode: 'GP', country: 'GUADELOUPE' },
  { countryCode: 'GQ', country: 'EQUATORIAL GUINEA ', available: 'PCT', incomplete: 'OA, GQ', tier: 3 },
  { countryCode: 'GR', country: 'GREECE', available: 'PCT, EP', incomplete: 'GR, EP Validations', tier: 2 },
  { countryCode: 'GS', country: 'SANDWICH ISLANDS' },
  { countryCode: 'GT', country: 'GUATEMALA', available: 'PCT', incomplete: 'GT', tier: 3 },
  { countryCode: 'GU', country: 'GUAM' },
  { countryCode: 'GW', country: 'GUINEA-BISSAU', available: 'PCT', incomplete: 'OA, GW', tier: 3 },
  { countryCode: 'GY', country: 'GUYANA', available: '', incomplete: 'GY', tier: 4 },
  { countryCode: 'HK', country: 'HONG KONG', available: 'HK', incomplete: '', tier: 1 },
  { countryCode: 'HM', country: 'HEARD ISLAND' },
  { countryCode: 'HN', country: 'HONDURAS', available: 'PCT', incomplete: 'HN', tier: 3 },
  { countryCode: 'HR', country: 'CROATIA', available: 'PCT, EP', incomplete: 'HR, EP Validations', tier: 2 },
  { countryCode: 'HT', country: 'HAITI', available: '', incomplete: 'HT', tier: 4 },
  { countryCode: 'HU', country: 'HUNGARY', available: 'PCT, EP, HU, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'ID', country: 'INDONESIA', available: 'PCT, ID', incomplete: '', tier: 1 },
  { countryCode: 'IE', country: 'IRELAND', available: 'PCT, EP, IE, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'IL', country: 'ISRAEL', available: 'PCT, IL', incomplete: '', tier: 1 },
  { countryCode: 'IN', country: 'INDIA', available: 'PCT, IN', incomplete: '', tier: 1 },
  { countryCode: 'IO', country: 'INDIAN OCEAN TERRITORY' },
  { countryCode: 'IQ', country: 'IRAQ', available: '', incomplete: 'IQ', tier: 4 },
  { countryCode: 'IR', country: 'IRAN', available: 'PCT', incomplete: 'IR', tier: 3 },
  { countryCode: 'IS', country: 'ICELAND', available: 'PCT, EP, IS, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'IT', country: 'ITALY', available: 'PCT, EP, IT, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'JM', country: 'JAMAICA', available: '', incomplete: 'JM', tier: 4 },
  { countryCode: 'JO', country: 'JORDAN', available: 'PCT', incomplete: 'JO', tier: 3 },
  { countryCode: 'JP', country: 'JAPAN', available: 'PCT, JP', incomplete: '', tier: 1 },
  { countryCode: 'KE', country: 'KENYA', available: 'PCT, AP', incomplete: 'KE', tier: 2 },
  { countryCode: 'KG', country: 'KYRGYZSTAN', available: 'PCT, EA', incomplete: 'KG', tier: 2 },
  { countryCode: 'KH', country: 'CAMBODIA', available: 'PCT, KH', incomplete: '', tier: 1 },
  { countryCode: 'KI', country: 'KIRIBATI' },
  { countryCode: 'KM', country: 'COMOROS', available: 'PCT', incomplete: 'OA, KM', tier: 3 },
  { countryCode: 'KN', country: 'SAINT KITTS AND NEVIS' },
  { countryCode: 'KP', country: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF" },
  { countryCode: 'KR', country: 'KOREA, REPUBLIC OF', available: 'PCT, KR', incomplete: '', tier: 1 },
  { countryCode: 'KW', country: 'KUWAIT' },
  { countryCode: 'KY', country: 'CAYMAN ISLANDS' },
  { countryCode: 'KZ', country: 'KAZAKHSTAN', available: 'PCT, EA', incomplete: 'KZ', tier: 2 },
  { countryCode: 'LA', country: 'LAOS', available: 'PCT, LA', incomplete: '', tier: 1 },
  { countryCode: 'LB', country: 'LEBANON', available: '', incomplete: 'LB', tier: 4 },
  { countryCode: 'LC', country: 'SAINT LUCIA' },
  { countryCode: 'LI', country: 'LIECHTENSTEIN', available: 'PCT, EP, LI, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'LK', country: 'SRI LANKA', available: 'PCT', incomplete: 'LK', tier: 3 },
  { countryCode: 'LR', country: 'LIBERIA', available: 'PCT, AP', incomplete: 'LR', tier: 2 },
  { countryCode: 'LS', country: 'LESOTHO', available: 'PCT, AP', incomplete: 'LS', tier: 2 },
  { countryCode: 'LT', country: 'LITHUANIA', available: 'PCT, EP', incomplete: 'LT, EP Validations', tier: 2 },
  { countryCode: 'LU', country: 'LUXEMBOURG', available: 'PCT, EP, LU, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'LV', country: 'LATVIA', available: 'PCT, EP', incomplete: 'LV, EP Validations', tier: 2 },
  { countryCode: 'LY', country: 'LIBYA' },
  { countryCode: 'MA', country: 'MOROCCO', available: 'PCT', incomplete: 'MA', tier: 3 },
  { countryCode: 'MC', country: 'MONACO', available: 'PCT, EP', incomplete: 'MC, EP Validations', tier: 2 },
  {
    countryCode: 'MD',
    country: 'MOLDOVA, REPUBLIC OF',
    available: 'PCT, EP',
    incomplete: 'MD, EP Validations',
    tier: 2,
  },
  { countryCode: 'ME', country: 'MONTENEGRO', available: 'PCT, EP', incomplete: 'ME, EP Validations', tier: 2 },
  { countryCode: 'MG', country: 'MADAGASCAR' },
  { countryCode: 'MH', country: 'MARSHALL ISLANDS' },
  { countryCode: 'MK', country: 'NORTH MACEDONIA' },
  { countryCode: 'ML', country: 'MALI', available: 'PCT', incomplete: 'OA, ML', tier: 3 },
  { countryCode: 'MM', country: 'MYANMAR', available: '', incomplete: 'MM', tier: 4 },
  { countryCode: 'MN', country: 'MONGOLIA', available: 'PCT', incomplete: 'MN', tier: 3 },
  { countryCode: 'MO', country: 'MACAU', available: '', incomplete: 'MO', tier: 4 },
  { countryCode: 'MP', country: 'NORTHERN MARIANA ISLANDS' },
  { countryCode: 'MQ', country: 'MARTINIQUE' },
  { countryCode: 'MR', country: 'MAURITANIA', available: 'PCT', incomplete: 'OA, MR', tier: 3 },
  { countryCode: 'MS', country: 'MONTSERRAT' },
  { countryCode: 'MT', country: 'MALTA', available: 'PCT, EP', incomplete: 'MT, EP Validations', tier: 2 },
  { countryCode: 'MU', country: 'MAURITIUS', available: '', incomplete: 'MU', tier: 4 },
  { countryCode: 'MV', country: 'MALDIVES' },
  { countryCode: 'MW', country: 'MALAWI', available: 'PCT, AP', incomplete: 'MW', tier: 2 },
  { countryCode: 'MX', country: 'MEXICO', available: 'PCT, MX', incomplete: '', tier: 1 },
  { countryCode: 'MY', country: 'MALAYSIA', available: 'PCT, MY', incomplete: '', tier: 1 },
  { countryCode: 'MZ', country: 'MOZAMBIQUE', available: 'PCT, AP', incomplete: 'MZ', tier: 2 },
  { countryCode: 'NA', country: 'NAMIBIA', available: 'PCT, AP', incomplete: 'NA', tier: 2 },
  { countryCode: 'NC', country: 'NEW CALEDONIA' },
  { countryCode: 'NE', country: 'NIGER', available: 'PCT', incomplete: 'OA, NE', tier: 3 },
  { countryCode: 'NF', country: 'NORFOLK ISLAND' },
  { countryCode: 'NG', country: 'NIGERIA', available: '', incomplete: 'NG', tier: 4 },
  { countryCode: 'NI', country: 'NICARAGUA', available: 'PCT', incomplete: 'NI', tier: 3 },
  { countryCode: 'NL', country: 'NETHERLANDS', available: 'PCT, EP, NL, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'NO', country: 'NORWAY', available: 'PCT, EP, NO, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'NP', country: 'NEPAL', available: '', incomplete: 'NP', tier: 4 },
  { countryCode: 'NR', country: 'NAURU', available: '', incomplete: 'NR', tier: 4 },
  { countryCode: 'NU', country: 'NIUE' },
  { countryCode: 'NZ', country: 'NEW ZEALAND', available: 'PCT, NZ', incomplete: '', tier: 1 },
  { countryCode: 'OM', country: 'OMAN', available: '', incomplete: 'OM', tier: 4 },
  { countryCode: 'PA', country: 'PANAMA', available: 'PCT', incomplete: 'PA', tier: 3 },
  { countryCode: 'PE', country: 'PERU', available: 'PCT', incomplete: 'PE', tier: 3 },
  { countryCode: 'PF', country: 'FRENCH POLYNESIA' },
  { countryCode: 'PG', country: 'PAPUA NEW GUINEA' },
  { countryCode: 'PH', country: 'PHILIPPINES', available: 'PCT, PH', incomplete: '', tier: 1 },
  { countryCode: 'PK', country: 'PAKISTAN', available: '', incomplete: 'PK', tier: 4 },
  { countryCode: 'PL', country: 'POLAND', available: 'PCT, EP, PL, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'PM', country: 'SAINT PIERRE' },
  { countryCode: 'PN', country: 'PITCAIRN ISLANDS' },
  { countryCode: 'PR', country: 'PUERTO RICO' },
  { countryCode: 'PS', country: 'PALESTINE' },
  { countryCode: 'PT', country: 'PORTUGAL', available: 'PCT, EP, PT, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'PW', country: 'PALAU' },
  { countryCode: 'PY', country: 'PARAGUAY', available: '', incomplete: 'PY', tier: 4 },
  { countryCode: 'QA', country: 'QATAR' },
  { countryCode: 'RE', country: 'REUNION' },
  { countryCode: 'RO', country: 'ROMANIA', available: 'PCT, EP', incomplete: 'RO, EP Validations', tier: 2 },
  { countryCode: 'RS', country: 'SERBIA', available: 'PCT, EP', incomplete: 'RS, EP Validations', tier: 2 },
  { countryCode: 'RU', country: 'RUSSIAN FEDERATION', available: 'PCT, EA, RU', incomplete: '', tier: 1 },
  { countryCode: 'RW', country: 'RWANDA', available: 'PCT, AP', incomplete: 'RW', tier: 2 },
  { countryCode: 'SA', country: 'SAUDI ARABIA', available: 'PCT', incomplete: 'SA', tier: 3 },
  { countryCode: 'SB', country: 'SOLOMON ISLANDS', available: '', incomplete: 'SB', tier: 4 },
  { countryCode: 'SC', country: 'SEYCHELLES' },
  { countryCode: 'SD', country: 'SUDAN', available: 'PCT, AP', incomplete: 'SD', tier: 2 },
  { countryCode: 'SE', country: 'SWEDEN', available: 'PCT, EP, SE, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'SG', country: 'SINGAPORE', available: 'PCT, SG', incomplete: '', tier: 1 },
  { countryCode: 'SH', country: 'SAINT HELENA' },
  { countryCode: 'SI', country: 'SLOVENIA', available: 'PCT, EP', incomplete: 'SI, EP Validations', tier: 2 },
  { countryCode: 'SJ', country: 'SVALBARD' },
  { countryCode: 'SK', country: 'SLOVAKIA', available: 'PCT, EP', incomplete: 'SK, EP Validations', tier: 2 },
  { countryCode: 'SL', country: 'SIERRA LEONE', available: 'PCT, AP', incomplete: 'SL', tier: 2 },
  { countryCode: 'SM', country: 'SAN MARINO', available: 'PCT, EP', incomplete: 'SM, EP Validations', tier: 2 },
  { countryCode: 'SN', country: 'SENEGAL', available: 'PCT', incomplete: 'OA, SN', tier: 3 },
  { countryCode: 'SO', country: 'SOMALIA', available: '', incomplete: 'SO', tier: 4 },
  { countryCode: 'SR', country: 'SURINAME', available: '', incomplete: 'SR', tier: 4 },
  { countryCode: 'SS', country: 'SOUTH SUDAN', available: '', incomplete: 'SS', tier: 4 },
  { countryCode: 'ST', country: 'SAO TOME' },
  { countryCode: 'SU', country: 'UNION OF SOVIET SOCIALIST REPUBLICS', available: '', incomplete: 'SU', tier: 4 },
  { countryCode: 'SV', country: 'EL SALVADOR', available: 'PCT', incomplete: 'SV', tier: 3 },
  { countryCode: 'SY', country: 'SYRIA', available: '', incomplete: 'SY', tier: 4 },
  { countryCode: 'SZ', country: 'SWAZILAND', available: 'PCT, AP', incomplete: 'SZ', tier: 2 },
  { countryCode: 'TC', country: 'CAICOS ISLANDS' },
  { countryCode: 'TD', country: 'CHAD', available: 'PCT', incomplete: 'OA, TD', tier: 3 },
  { countryCode: 'TF', country: 'FRENCH TERRITORIES' },
  { countryCode: 'TG', country: 'TOGO', available: 'PCT', incomplete: 'OA, TG', tier: 3 },
  { countryCode: 'TH', country: 'THAILAND', available: 'PCT, TH', incomplete: '', tier: 1 },
  { countryCode: 'TJ', country: 'TAJIKISTAN', available: 'PCT, EA', incomplete: 'TJ', tier: 2 },
  { countryCode: 'TK', country: 'TOKELAU' },
  { countryCode: 'TL', country: 'TIMORLESTE' },
  { countryCode: 'TM', country: 'TURKMENISTAN', available: 'PCT, EA', incomplete: 'TM', tier: 2 },
  { countryCode: 'TN', country: 'TUNISIA', available: 'PCT', incomplete: 'TN', tier: 3 },
  { countryCode: 'TO', country: 'TONGA', available: '', incomplete: 'TO', tier: 4 },
  { countryCode: 'TP', country: 'EAST TIMOR', available: '', incomplete: 'TP', tier: 4 },
  { countryCode: 'TR', country: 'TURKEY', available: 'PCT, EP, TR, EP Validations', incomplete: '', tier: 1 },
  { countryCode: 'TT', country: 'TRINIDAD AND TOBAGO', available: 'PCT', incomplete: 'TT', tier: 3 },
  { countryCode: 'TV', country: 'TUVALU' },
  { countryCode: 'TW', country: 'TAIWAN, PROVINCE OF CHINA', available: 'TW', incomplete: '', tier: 1 },
  { countryCode: 'TZ', country: 'TANZANIA', available: 'PCT, AP', incomplete: 'AP', tier: 2 },
  { countryCode: 'UA', country: 'UKRAINE', available: 'PCT', incomplete: 'UA', tier: 3 },
  { countryCode: 'UG', country: 'UGANDA', available: 'PCT, AP', incomplete: 'AP', tier: 3 },
  { countryCode: 'UM', country: 'US MINOR ISLANDS' },
  { countryCode: 'US', country: 'UNITED STATES OF AMERICA', available: 'PCT, US', incomplete: '', tier: 1 },
  { countryCode: 'UY', country: 'URUGUAY', available: '', incomplete: 'UY', tier: 4 },
  { countryCode: 'UZ', country: 'UZBEKISTAN', available: 'PCT', incomplete: 'UZ', tier: 3 },
  { countryCode: 'VA', country: 'VATICAN CITY ', available: '', incomplete: 'VA', tier: 4 },
  { countryCode: 'VC', country: 'SAINT VINCENT' },
  { countryCode: 'VE', country: 'VENEZUELA', available: '', incomplete: 'VE', tier: 4 },
  { countryCode: 'VG', country: 'BRITISH VIRGIN ISLANDS' },
  { countryCode: 'VI', country: 'US VIRGIN ISLANDS' },
  { countryCode: 'VN', country: 'VIETNAM', available: 'PCT, VN', incomplete: '', tier: 1 },
  { countryCode: 'VU', country: 'VANUATU', available: '', incomplete: 'VU', tier: 4 },
  { countryCode: 'WF', country: 'WALLIS AND FUTUNA' },
  { countryCode: 'WS', country: 'SAMOA', available: '', incomplete: 'WS', tier: 4 },
  { countryCode: 'YE', country: 'YEMEN', available: '', incomplete: 'YE', tier: 4 },
  { countryCode: 'YT', country: 'MAYOTTE' },
  { countryCode: 'YU', country: 'YUGOSLAVIA', available: '', incomplete: 'YU', tier: 4 },
  { countryCode: 'ZA', country: 'SOUTH AFRICA', available: 'PCT, AP, ZA', incomplete: '', tier: 1 },
  { countryCode: 'ZM', country: 'ZAMBIA', available: 'PCT, AP', incomplete: 'AP', tier: 2 },
  { countryCode: 'ZR', country: 'ZAIRE', available: '', incomplete: 'ZR', tier: 4 },
  { countryCode: 'ZW', country: 'ZIMBABWE', available: 'PCT, AP', incomplete: 'ZW', tier: 2 },
]
