import { ChartConfiguration } from '@pp/common/typescript/dashboard.type'
import { Entity } from '@pp/common/typescript/meta.interface'
import { FamilyType, LanguageEnum } from '@pp/modules/analyse/common/types/app.type'

export type ModalObject = { open: boolean; version: string }
export interface ApplicationStoreInterface {
  agentUrls: string[]
  applicantUrls: string[]
  chartConfigurations: ChartConfiguration[]
  familyType: FamilyType[]
  hasAdminSection: boolean
  hasApiAccess: boolean
  hasApplicants: boolean
  hasAttorneysTab: boolean
  hasAutomatedReports: boolean
  hasBuildCharts: boolean
  hasCaseExchangeTab: boolean
  hasChartFilters: boolean
  hasDashboards: boolean
  hasDataAccess: boolean
  hasDateRangeSelector: boolean
  hasEditableProfiles: boolean

  hasExecutiveSummaryTab: boolean
  hasExportPermission: boolean
  hasExtendedSearchResults: boolean
  hasFamilyCheck: boolean
  hasFilingCheck: boolean
  hasFilingsTab: boolean
  hasInsightsSearch: boolean
  hasInsightsTab: boolean
  hasInstructionCheck: boolean
  hasIntegration: boolean
  hasKpiAccess: boolean
  hasLastSearches: boolean
  hasLawFirms: boolean
  hasLocationPermission: boolean
  hasMapView: boolean
  hasNameSearch: boolean
  hasNegativeFilters: boolean
  hasNotes: boolean
  hasOneOnOneComparison: boolean
  hasOverviewTab: boolean
  hasPatentsPermission: boolean
  hasPeerGroupCheck: boolean
  hasPersistentFilters: boolean
  hasRankingInfoTab: boolean
  hasSavedSearches: boolean
  hasSearchConstraints: boolean
  hasStandardSearch: boolean
  hasSuitabilityCheck: boolean
  hasSupport: boolean
  hasTrademarksPermission: boolean
  hasUnconfirmedPermission: boolean
  hasValidations: boolean
  hasValidationsPermission: boolean
  hasWhiteSpotCheck: boolean
  isAdmin: boolean
  isPdfExport: boolean
  language: LanguageEnum
  metadata: Entity[]
  modal: ModalObject
  permissionsFetched: boolean | null
  showNotesSection: boolean
  showUnconfirmedExternalLinks: boolean
}

export enum PermissionType {
  AttorneysTab = 'Attorneys Tab',
  CustomCharts = 'Custom Charts',
  Dashboard = 'Dashboard',
  Export = 'Export',
  ExtendedSearchResults = 'Extended Search Results',
  FilingCheck = 'Filing Check',
  FilingsTab = 'Filings Tab',
  FilingsView = 'Filings View',
  Insights = 'Insights',
  Location = 'LocationSearch',
  MapView = 'Map View',
  Mutuality = 'Mutuality',
  Patents = 'Patents',
  SearchConstraints = 'Search Constraints',
  Similarity = 'Similarity',
  TechOverlap = 'Tech Overlap',
  Trademarks = 'Trademarks',
  Validations = 'Validations',
  WhiteSpotCheck = 'White Spot Check',
}

export enum VersionsType {
  Basic = 'Basic',
  Enterprise = 'Enterprise',
  Free = 'Free',
  Professional = 'Professional',
  Public = 'Public',
  Standard = 'Standard',
  Trial = 'Trial',
}
