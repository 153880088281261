import { css, cx } from '@emotion/css'
import { Trans } from '@lingui/react'
import * as routes from '@pp/routing/routes'
import { useStores } from '@pp/store/useStore.hook'
import { observer } from 'mobx-react-lite'
import { Menu } from 'semantic-ui-react'

import { disabledStyleCss, slideOutCss, submenuCss } from '../../../sidebar.css'
import NewLabel from '../../NewLabel'
import { DefaultMenuItemsProps } from '../analyze-menu/AnalyzeItems'

const InsightsItems = ({ menus, minimizedSideBar, isMobile, active, onNavLinkClick }: DefaultMenuItemsProps) => {
  const { applicationStore } = useStores()

  const handleClick = (route: string) => {
    onNavLinkClick(route)
  }

  return (
    <Menu.Menu className={cx(submenuCss(false, isMobile), !minimizedSideBar && slideOutCss(menus.checkMenu))}>
      <Menu.Item>
        {applicationStore.hasSimilarity ? (
          <div
            className={css`
              display: inline-flex;
            `}
          >
            <span
              className={active.includes(routes.similarityRoute) ? 'active' : ''}
              onClick={() => handleClick(routes.similarityAgentsRoute)}
            >
              <div className="notch"></div>
              <Trans id="header.title.similarity" />
            </span>
            <NewLabel />
          </div>
        ) : null}
      </Menu.Item>

      <Menu.Item>
        {applicationStore.hasMutuality ? (
          <div
            className={css`
              display: inline-flex;
            `}
          >
            <span
              className={active.includes(routes.mutualityRoute) ? 'active' : ''}
              onClick={() => handleClick(routes.mutualityRoute)}
            >
              <div className="notch"></div>
              <Trans id="header.title.mutuality" />
            </span>
            <NewLabel />
          </div>
        ) : null}
      </Menu.Item>

      <Menu.Item>
        {applicationStore.hasWhiteSpotCheck ? (
          <span
            className={active.includes(routes.whitespotCheckRoute) ? 'active' : ''}
            onClick={() => handleClick(routes.whitespotAgentsCheckRoute)}
          >
            <div className="notch"></div>
            <Trans id="sidebar.menu.whitespot" />
          </span>
        ) : (
          <div
            className={disabledStyleCss(isMobile)}
            onClick={() => applicationStore.setModal({ open: true, version: '' })}
          >
            <div className="notch"></div>
            <Trans id="sidebar.menu.whitespot" />
          </div>
        )}
      </Menu.Item>
      <Menu.Item>
        {applicationStore.hasTechOverlap ? (
          <div
            className={css`
              display: inline-flex;
            `}
          >
            <span
              className={active.includes(routes.techOverlapRoute) ? 'active' : ''}
              onClick={() => handleClick(routes.techOverlapAgentsCheckRoute)}
            >
              <div className="notch"></div>
              <Trans id="sidebar.menu.techOverlap" />
            </span>
            <NewLabel />
          </div>
        ) : null}
      </Menu.Item>
    </Menu.Menu>
  )
}

export default observer(InsightsItems)
