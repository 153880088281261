/*
db.getCollection('contacts_new').aggregate( [
  { $match : {applnCount: {$gte:10}} },
  { $group: { _id: "$country" } },
  { $sort: {"_id": 1} }
] );
*/

export const countriesRaw = [
  'AD',
  'AE',
  'AG',
  'AI',
  'AL',
  'AM',
  'AN',
  'AR',
  'AT',
  'AU',
  'AW',
  'AZ',
  'BA',
  'BB',
  'BD',
  'BE',
  'BG',
  'BH',
  'BI',
  'BM',
  'BN',
  'BO',
  'BR',
  'BS',
  'BW',
  'BY',
  'BZ',
  'CA',
  'CH',
  'CI',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CR',
  'CU',
  'CV',
  'CW',
  'CY',
  'CZ',
  'DD',
  'DE',
  'DK',
  'DM',
  'DO',
  'DZ',
  'EC',
  'EE',
  'EG',
  'ES',
  'FI',
  'FJ',
  'FR',
  'GB',
  'GD',
  'GE',
  'GI',
  'GN',
  'GP',
  'GR',
  'GT',
  'HK',
  'HR',
  'HT',
  'HU',
  'ID',
  'IE',
  'IL',
  'IN',
  'IR',
  'IS',
  'IT',
  'JE',
  'JM',
  'JO',
  'JP',
  'KE',
  'KG',
  'KH',
  'KI',
  'KN',
  'KP',
  'KR',
  'KW',
  'KY',
  'KZ',
  'LA',
  'LB',
  'LI',
  'LK',
  'LR',
  'LT',
  'LU',
  'LV',
  'MA',
  'MC',
  'MD',
  'ME',
  'MG',
  'MH',
  'MK',
  'MN',
  'MO',
  'MR',
  'MS',
  'MT',
  'MU',
  'MX',
  'MY',
  'NA',
  'NG',
  'NL',
  'NO',
  'NZ',
  'OM',
  'PA',
  'PE',
  'PG',
  'PH',
  'PK',
  'PL',
  'PR',
  'PT',
  'PY',
  'QA',
  'RO',
  'RS',
  'RU',
  'SA',
  'SC',
  'SD',
  'SE',
  'SG',
  'SI',
  'SK',
  'SM',
  'SN',
  'ST',
  'SU',
  'SV',
  'SY',
  'TC',
  'TD',
  'TH',
  'TJ',
  'TM',
  'TN',
  'TO',
  'TR',
  'TT',
  'TW',
  'TZ',
  'UA',
  'US',
  'UY',
  'UZ',
  'VC',
  'VE',
  'VG',
  'VN',
  'VU',
  'WS',
  'YU',
  'ZA',
  'ZW',
]
