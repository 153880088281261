import { useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { css } from '@emotion/css'
import { Trans } from '@lingui/react'
import LanguageChineseIcon from '@pp/assets/icons/language-chinese-icon.svg'
import LanguageEnglishIcon from '@pp/assets/icons/language-english-icon.svg'
import ReferralIcon from '@pp/assets/icons/referral-icon.svg'
import SettingsIcon from '@pp/assets/icons/settings-icon.svg'
import * as routes from '@pp/routing/routes'
import { observer } from 'mobx-react-lite'
import { useLocation } from 'react-router-dom'
import { Menu, Button, Dropdown, DropdownItemProps } from 'semantic-ui-react'

import { LanguageEnum } from '../../../../../modules/analyse/common/types/app.type'
import { increaseSpecificity } from '../../../../css/css.helper'
import Icon from '../../../icon/Icon'
import { nonAuthenticatedIconCss } from '../sidebar.css'

export const languageOptions: DropdownItemProps[] = [
  { text: 'English', value: LanguageEnum.EN },
  { text: 'Chinese', value: LanguageEnum.CN },
]

const bottomIconsCss = (isMobile: boolean) => css`
  padding: 8px 24px ${isMobile ? '0' : '19px'} 24px;
  width: 100%;
  display: flex;
  flex-direction: ${isMobile ? 'row' : 'column'};
  justify-content: center;
  align-items: center;

  div.item {
    &::before {
      display: none;
    }
  }

  button {
    margin: 0 !important;
  }
`

const menuItemsCss = (flexDirection: boolean) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${flexDirection ? 'column' : 'row'};

  .item {
    padding: 13px !important;
  }
`

type Props = {
  active: string
  isMobile: boolean
  isSmallHeight: boolean
  locale: LanguageEnum
  minimizedSideBar: boolean
  onNavLinkClick: (route: string) => void
  setLocale: (language: LanguageEnum) => void
}

const FooterButtons = ({ minimizedSideBar, isSmallHeight, locale, setLocale, isMobile, onNavLinkClick }: Props) => {
  const { pathname } = useLocation()
  const { logout, loginWithRedirect, isAuthenticated } = useAuth0()
  const settingActive = pathname.includes(routes.settingsRoute)
  const [languageIcon, setLanguageIcon] = useState(
    locale === LanguageEnum.EN ? LanguageEnglishIcon : LanguageChineseIcon,
  )

  const onLogoutClick = () => {
    logout({ returnTo: window.location.origin })
    localStorage.removeItem('token')
  }

  const onLoginClick = () => {
    loginWithRedirect({ appState: { returnTo: pathname } })
  }

  const onLanguageClick = (locale: LanguageEnum) => {
    setLanguageIcon(locale === LanguageEnum.EN ? LanguageEnglishIcon : LanguageChineseIcon)
    setLocale(locale)
    window.location.reload()
  }

  const onSettingsClick = () => {
    onNavLinkClick(routes.settingsRoute)
  }

  return (
    <div className={bottomIconsCss(isMobile)}>
      <>
        <div className={menuItemsCss(minimizedSideBar && !isSmallHeight)}>
          <Menu.Item>
            {isAuthenticated ? (
              <div onClick={() => window.open('https://www.ip-pilot.com/en/referral-program/', '_blank')}>
                <div
                  className={css`
                    background: #4c4c4c;
                    mask-image: url(${ReferralIcon});
                    height: 20px;
                    width: 20px;
                    cursor: pointer;

                    &:hover {
                      background: var(--primary-color);
                    }
                  `}
                ></div>
              </div>
            ) : (
              <div>
                <div
                  className={css`
                    background: #4c4c4c;
                    mask-image: url(${ReferralIcon});
                    height: 20px;
                    width: 20px;
                  `}
                ></div>
              </div>
            )}
          </Menu.Item>

          <Menu.Item>
            <Dropdown
              upward
              className={css`
                ${increaseSpecificity(`
                  border: none !important;
                `)}
              `}
              icon={null}
              floating
              options={languageOptions}
              onChange={(_, data) => onLanguageClick(data.value as LanguageEnum)}
              trigger={
                <div
                  className={css`
                    background: #4c4c4c;
                    mask-image: url(${languageIcon});
                    height: 19px;
                    width: 33px;
                    cursor: pointer;

                    &:hover {
                      background: var(--primary-color);
                    }
                  `}
                ></div>
              }
            />
          </Menu.Item>

          <Menu.Item>
            {isAuthenticated ? (
              <span onClick={onSettingsClick}>
                <div
                  className={css`
                    background: ${settingActive ? 'var(--primary-color)' : '#4c4c4c'};
                    mask-image: url(${SettingsIcon});
                    height: 20px;
                    width: 20px;
                    cursor: pointer;

                    &:hover {
                      background: var(--primary-color);
                    }
                  `}
                ></div>
              </span>
            ) : (
              <div className={nonAuthenticatedIconCss}>
                <div
                  className={css`
                    background: #4c4c4c;
                    mask-image: url(${SettingsIcon});
                    height: 20px;
                    width: 20px;
                  `}
                ></div>
              </div>
            )}
          </Menu.Item>
        </div>
        <Menu.Item
          className={css`
            padding: 0 !important;
            ${!isMobile && 'width: 100%;'}
          `}
        >
          {isAuthenticated ? (
            <Button
              className={css`
                ${increaseSpecificity(`
                  display: inline-flex !important;
                  align-items: center !important;
                  justify-content: center !important;
                  border-radius: 8px !important;
                  padding: ${minimizedSideBar ? '8px !important' : '20px'};
                `)}
              `}
              negative
              onClick={onLogoutClick}
              fluid
            >
              <Icon iconName="icon-power" size={14} margin="0 8px 0 0" />
              {minimizedSideBar ? null : <Trans id="sidebar.buttons.tooltip.logout" />}
            </Button>
          ) : (
            <Button
              className={css`
                ${increaseSpecificity(`
                  display: inline-flex !important;
                  align-items: center !important;
                  justify-content: center !important;
                  border-radius: 8px !important;
                  padding: ${minimizedSideBar ? '8px !important' : '20px'};
                `)}
              `}
              primary
              onClick={onLoginClick}
              fluid
            >
              <Icon iconName="icon-power" size={14} margin={minimizedSideBar ? '0' : '0 8px 0 0'} />
              {/* <Image
                className={css`
                  margin-right: ${minimizedSideBar ? '0px' : '4px'};
                `}
                src={LoginIcon}
                alt="analyse-shevron"
                width="20px"
                height="20px"
              /> */}
              {minimizedSideBar ? null : <Trans id="sidebar.buttons.tooltip.login" />}
            </Button>
          )}
        </Menu.Item>
      </>
    </div>
  )
}

export default observer(FooterButtons)
