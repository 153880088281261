import { EntitySearchResult } from '@pp/common/components/entity-name-search/entity-search.interface'
import { arrayFromString } from '@pp/common/helpers'
import { DateType } from '@pp/modules/analyse/common/profile-header/components/default-filters/default-filters.type'
import { FamilyType } from '@pp/modules/analyse/common/types/app.type'
import { RootStoreInterface } from '@pp/store/root.store'
import _ from 'lodash'
import { makeAutoObservable } from 'mobx'

import {
  FLimitingClauseType,
  RadiusUnit,
  RadiusValue,
  SearchParameters,
} from '../../../../search/advanced-search/typescript/search.interface'
import { ApplicantResults } from '../../common/components/SimilarityUiState'

export type SimilarityApplServerFilters = {
  applicant_url: string[]
  date_from: string[]
  date_to: string[]
  date_type: DateType[]
  family_type: FamilyType[]
  target_applicant_country?: string[] | undefined
}

export type SimilarityApplSearchParams = {
  any: []
  applicant_country: string[]
  date_from: number
  date_to: number
  date_type: string
}

type SimilaritySearchInterface = {
  focalFirms: EntitySearchResult[]
  limitingClauses: FLimitingClauseType[]
  results: ApplicantResults | null
  searchParameters: SearchParameters
}

const initialSearchParameters: SearchParameters = {
  entityGeoBoundsName: 'Washington, D.C.',
  entityGeoBoundsHigh: [38.9958641, -76.909393],
  entityGeoBoundsLow: [38.7916449, -77.119759],
  entityGeoBoundsRadius: RadiusValue._0,
  entityGeoBoundsRadiusUnit: RadiusUnit.MI,
  entityCountry: [],
  primaryAttributeAuthority: [],
  primaryAttributeCountry: [],
  primaryAttributeGeoBoundsName: 'Washington, D.C.',
  primaryAttributeGeoBoundsHigh: [38.9958641, -76.909393],
  primaryAttributeGeoBoundsLow: [38.7916449, -77.119759],
  primaryAttributeGeoBoundsRadius: RadiusValue._0,
  primaryAttributeGeoBoundsRadiusUnit: RadiusUnit.MI,
  date_type: 'm',
  date_from: new Date().getFullYear() - 3,
  date_to: new Date().getFullYear(),
  filingReceivedFromCountry: [],
  filingReceivedFromPublAuth: [],
  filingReceivedFromGeoBoundsName: 'Washington, D.C.',
  filingReceivedFromGeoBoundsHigh: [38.9958641, -76.909393],
  filingReceivedFromGeoBoundsLow: [38.7916449, -77.119759],
  filingReceivedFromGeoBoundsRadius: RadiusValue._0,
  filingReceivedFromGeoBoundsRadiusUnit: RadiusUnit.MI,
  filingSentToCountry: [],
  filingSentToPublnAuth: [],
  filingSentToGeoBoundsName: 'Washington, D.C.',
  filingSentToGeoBoundsHigh: [38.9958641, -76.909393],
  filingSentToGeoBoundsLow: [38.7916449, -77.119759],
  filingSentToGeoBoundsRadius: RadiusValue._0,
  filingSentToGeoBoundsRadiusUnit: RadiusUnit.MI,
  receivedCasesApplicantDecision: true,
  sentCasesApplicantDecision: true,
  secondLevelCasesApplicantDecision: true,
  representative_type: [],
  appln_type: [],
  rep_location: [],
  nice_class: [],
  lifecycle: [],
  nice_category: [],
  verbal_element: [],
  ipc_class: [],
  techn_field_nr: [],
  sequence: [],
  grant_status: [],
  route: [],
  legal_state: [],
  relevant_count_from: [],
  relevant_count_to: [],
  applicant_country: [],
  direction: [],
  applicant_origin: [],
  applicant_url: [],
  rep_url: [],
  rep_country: [],
  publn_auth: [],
  int_appln_auth: [],
  int_decision_maker: [],
  dom_publn_auth: [],
  dom_rep_country: [],
  dom_rep_url: [],
  int_publn_auth: [],
  int_rep_country: [],
  int_rep_url: [],
  foreign_rep_url: [],
  foreign_appln_auth: [],
  foreign_publn_auth: [],
  foreign_rep_country: [],
}

const newFilters = [{ prop: 'applicant_country' as const, value: FLimitingClauseType.ApplicantCountry }]

export class SimilarityApplicantsSearchStore implements SimilaritySearchInterface {
  private rootStore: RootStoreInterface
  focalFirms: EntitySearchResult[] = []
  searchParameters = initialSearchParameters
  results: ApplicantResults | null = null
  limitingClauses: FLimitingClauseType[] = []

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  get serverFilters() {
    const filter = {
      family_type: this.rootStore.applicationStore.familyType,
      date_from: [this.searchParameters.date_from.toString()],
      date_to: [this.searchParameters.date_to.toString()],
      date_type: [this.searchParameters.date_type.toUpperCase() as DateType],
      applicant_url: this.focalFirms.map((el) => el.value),
      use_summary: true,
      summary_sufficient_urls: 500,
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newFilters.forEach((el) => (filter[el.prop] = this.searchParameters[el.prop]))

    return _.omitBy(filter, (value) => (typeof value === 'object' ? _.isEmpty(value) : value === undefined))
  }

  setLimitingClauses = (clauses: FLimitingClauseType[]) => (this.limitingClauses = clauses)

  setFocalFirms = (focalFirms: EntitySearchResult[]) => (this.focalFirms = focalFirms)
  setResults = (results: ApplicantResults | null) => (this.results = results)

  setSearchParameter = (searchParameter: {}) => {
    this.searchParameters = Object.assign({}, this.searchParameters, searchParameter)
  }

  unsetSearchParameter = (searchParameter: string) => {
    this.searchParameters[searchParameter] = initialSearchParameters[searchParameter] as never
  }

  resetSearchUI = () => {
    this.limitingClauses = []
    this.searchParameters = initialSearchParameters

    const routerStore = this.rootStore.routerStore
    routerStore.replace(routerStore.location.pathname)
  }

  initializeSearchFiltersFromUrl = (queryParameters: string) => {
    const urlSearchParams = new URLSearchParams(queryParameters)

    const familyType = urlSearchParams.get('familyType')
    if (familyType) this.rootStore.applicationStore.setFamilyType(familyType.split(',') as FamilyType[])

    const limitingClauses = urlSearchParams.get('limitingClauses')
    if (limitingClauses) this.limitingClauses = limitingClauses.split(',') as FLimitingClauseType[]

    this.searchParameters.date_from = urlSearchParams.get('date_from')
      ? Number(urlSearchParams.get('date_from'))
      : initialSearchParameters.date_from

    this.searchParameters.date_to = urlSearchParams.get('date_to')
      ? Number(urlSearchParams.get('date_to'))
      : initialSearchParameters.date_to

    this.searchParameters.date_type = urlSearchParams.get('date_type') || initialSearchParameters.date_type

    newFilters.forEach((el) => {
      if (this.limitingClauses.includes(el.value)) {
        this.searchParameters[el.prop] =
          arrayFromString(urlSearchParams.get(el.prop)) || initialSearchParameters[el.prop]
      }
    })
  }

  updateSearchParamsFromFilters = (filters: SimilarityApplServerFilters) => {
    const familyType = this.rootStore.applicationStore.familyType
    const language = `locale=${this.rootStore.applicationStore.language}`
    const searchStrings: string[] = [language]

    // if (filters.applicant_url.length) searchStrings.push(`applicant_url=${filters.applicant_url.join(',')}`)
    if (this.limitingClauses.length) searchStrings.push(`limitingClauses=${this.limitingClauses}`)

    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) searchStrings.push(`${key}=${value && value.join(',')}`)
      else searchStrings.push(`${key}=${value}`)
    })

    const routerStore = this.rootStore.routerStore
    routerStore.replace(`${routerStore.location.pathname}?familyType=${familyType}&${searchStrings.join('&')}`)
  }
}
